import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Skeleton,
  SxProps,
  TextField,
  Typography,
} from '@mui/material';
import { useParams } from 'next/navigation';
import { useEffect, useState } from 'react';

import { useTranslation } from '@/app/i18n/client';
import { Languages, languages } from '@/app/i18n/settings';
import { getClientSupabaseClient } from '@/service/supabase.client';
import { getRoles, updateProfile } from '@/service/users.service';
import { ProfileWithRole, Role, Theme_enum } from '@/types/exported.types';

interface SettingsDialogProps {
  open: boolean;
  onClose: () => void;
  profile: ProfileWithRole | null;
  showPreferences?: boolean;
  editables?: {
    name?: boolean;
    email?: boolean;
    phoneNumber?: boolean;
    role?: boolean;
  };
}

const SettingsDialog: React.FC<SettingsDialogProps> = ({
  open,
  onClose,
  profile,
  showPreferences = true,
  editables,
}) => {
  const params = useParams();
  const lng = params?.lng ?? 'en';
  const { t } = useTranslation(typeof lng === 'string' ? lng : 'da');
  const [loading, setLoading] = useState<boolean>(true);
  const [language, setLanguage] = useState<Languages>(profile?.language ?? 'da');
  const [theme, setTheme] = useState<Theme_enum>(profile?.theme_preference ?? 'light');
  const [name, setName] = useState<string | undefined>(profile?.name ?? undefined);
  const [roles, setRoles] = useState<Role[]>([]);
  const [email, setEmail] = useState<string | undefined>(profile?.email_address ?? undefined);
  const [phoneNumberCountryCode, setPhoneNumberCountryCode] = useState<string | undefined>(
    profile?.phone_number_country_code ?? undefined
  );
  const [phoneNumber, setPhoneNumber] = useState<number | undefined>(profile?.phone_number ?? undefined);
  const [role, setRole] = useState<string | undefined>((profile?.role && profile.role[0].id) ?? undefined);

  useEffect(() => {
    (async () => {
      if (profile) {
        setLanguage(profile.language);
        setTheme(profile.theme_preference);
        setName(profile.name);
        setEmail(profile.email_address ?? '');
        setPhoneNumberCountryCode(profile.phone_number_country_code ?? undefined);
        setPhoneNumber(profile.phone_number ?? undefined);
        const { data: roles, error } = await getRoles(getClientSupabaseClient());
        if (error) {
          setLoading(false);
          throw error;
        } else {
          setRoles(roles);
          setLoading(false);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleChangeLanguage = (event: SelectChangeEvent<string | string[]>) => {
    const selectedLng = event.target.value as Languages;
    setLanguage(selectedLng);
  };

  const handleChangeTheme = (event: SelectChangeEvent<string | string[]>) => {
    const selectedTheme = event.target.value as Theme_enum;
    setTheme(selectedTheme);
  };

  const handleChangeRole = (event: SelectChangeEvent<string | string[]>) => {
    const selectedRole = event.target.value as Languages;
    setRole(selectedRole);
  };

  const handleSave = async () => {
    if (profile) {
      await updateProfile(getClientSupabaseClient(), profile!.id, {
        language,
        theme_preference: theme,
        name,
        email_address: email,
        phone_number_country_code: phoneNumberCountryCode,
        phone_number: phoneNumber,
        newRoleId: role,
      });
      location.reload();
    }
    onClose();
  };

  const selectContainerStyle: SxProps = {
    display: 'flex',
    flexDirection: 'column',
    gap: 1,
  };

  return (
    <Dialog PaperProps={{ sx: { minWidth: 400 } }} open={open} onClose={onClose}>
      <DialogTitle>{t('profile')}</DialogTitle>
      <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        {profile ? (
          <>
            <Box sx={selectContainerStyle}>
              <TextField
                disabled={!editables?.name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
                fullWidth
                variant="standard"
                label={t('name')}
                value={name}
              />
            </Box>
            <Box sx={selectContainerStyle}>
              <TextField
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                disabled={!editables?.email}
                fullWidth
                variant="standard"
                label={t('email')}
                value={email}
              />
            </Box>
            <Box sx={[selectContainerStyle, { flexDirection: 'row', alignItems: 'center' }]}>
              <TextField
                onChange={(e) => {
                  setPhoneNumberCountryCode(e.target.value);
                }}
                disabled={!editables?.phoneNumber}
                variant="standard"
                sx={{ width: '20%' }}
                label={t('code')}
                value={phoneNumberCountryCode}
                placeholder="+45"
              />
              <TextField
                onChange={(e) => {
                  setPhoneNumber(+e.target.value);
                }}
                disabled={!editables?.phoneNumber}
                fullWidth
                variant="standard"
                label={t('phoneNumber')}
                value={phoneNumber}
                type="tel"
              />
            </Box>
            <Box sx={selectContainerStyle}>
              {loading ? (
                <Skeleton height={32} />
              ) : (
                <FormControl disabled={!editables?.role} variant="standard" fullWidth>
                  <InputLabel id="role-select-label">{t('userType')}</InputLabel>
                  <Select labelId="role-select-label" value={role} onChange={handleChangeRole}>
                    {roles.map((role) => {
                      return (
                        <MenuItem key={role.id} value={role.id}>
                          {role.title}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              )}
            </Box>
            {showPreferences ? (
              <Box sx={[selectContainerStyle, { pt: 4 }]}>
                <Typography component="h2">{t('preferences')}</Typography>
                <Box sx={selectContainerStyle}>
                  <FormControl variant="standard" fullWidth>
                    <InputLabel id="language-select-label">{t('language')}</InputLabel>
                    <Select labelId="language-select-label" value={language} onChange={handleChangeLanguage}>
                      {languages.map((language) => {
                        return (
                          <MenuItem key={language} value={language}>
                            {t(`languages.${language}`)}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Box>
                <Box sx={selectContainerStyle}>
                  <FormControl variant="standard" fullWidth>
                    <InputLabel id="theme-select-label">{t('theme')}</InputLabel>
                    <Select id="theme-select" labelId="theme-select-label" value={theme} onChange={handleChangeTheme}>
                      <MenuItem value={'dark'}>{t(`themes.dark`)}</MenuItem>;
                      <MenuItem value={'light'}>{t(`themes.light`)}</MenuItem>;
                    </Select>
                  </FormControl>
                </Box>
              </Box>
            ) : null}
          </>
        ) : (
          <Typography>{t('profileNotFound')}</Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t('cancel')}</Button>
        <Button onClick={handleSave}>{t('save')}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default SettingsDialog;
