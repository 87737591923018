'use client';
import Help from '@mui/icons-material/Help';
import { Fab } from '@mui/material';
import { useState } from 'react';

import { HelpDialog } from './HelpDialog';

export const HelpButton: React.FC = () => {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const closeDialog = () => setDialogOpen(false);
  return (
    <>
      <Fab
        color={'primary'}
        sx={{ position: 'fixed', right: '8px', bottom: '8px', display: { xs: 'none', xl: 'inline-flex' } }}
        aria-label="help"
        onClick={() => setDialogOpen(true)}
      >
        <Help />
      </Fab>
      <HelpDialog open={dialogOpen} onClose={closeDialog} />
    </>
  );
};
