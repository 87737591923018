import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Link,
  Snackbar,
  TextField,
  Typography,
} from '@mui/material';
import isEmail from 'is-email';
import { useState } from 'react';

import { useTranslation } from '@/app/i18n/client';
import { getClientSupabaseClient } from '@/service/supabase.client';

type Props = {
  lng: string;
};

export const ResetPassword: React.FC<Props> = ({ lng }) => {
  const [resetPasswordDialogOpen, setResetPasswordDialogOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setError] = useState<string | null>(null);
  const [email, setEmail] = useState('');
  const { t } = useTranslation(lng);
  const close = () => {
    setEmail('');

    setLoading(false);
    setResetPasswordDialogOpen(false);
  };
  const validEmail = !!email && isEmail(email);
  const sendResetPasswordRequest = async () => {
    setLoading(true);
    const supabase = getClientSupabaseClient();
    const { data, error } = await supabase.auth.resetPasswordForEmail(email);
    console.log(data, error);
    if (error) {
      setError(error.message);
    } else {
      setSuccess(true);
    }
    close();
  };
  return (
    <Box>
      <Snackbar open={!!errorMessage} onClose={() => setError(null)} autoHideDuration={4000}>
        <Alert severity="error">
          <Typography>{errorMessage}</Typography>
        </Alert>
      </Snackbar>
      <Snackbar open={success} onClose={() => setSuccess(false)} autoHideDuration={4000}>
        <Alert severity="success">
          <Typography>{t('resetPasswordEmailSent')}</Typography>
        </Alert>
      </Snackbar>
      <Link sx={{ cursor: 'pointer' }} onClick={() => setResetPasswordDialogOpen(true)}>
        {t('forgotPassword')}
      </Link>
      <Dialog open={resetPasswordDialogOpen} onClose={close}>
        <DialogTitle>{t('resetPassword')}</DialogTitle>
        <DialogContent>
          <DialogContentText>{t('resetPasswordDescription')}</DialogContentText>
          <Box mt={2}>
            <TextField
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              label="Email"
              name="email"
              placeholder="you@example.com"
              required
              size="medium"
              fullWidth
              focused
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="text" onClick={close}>
            {t('close')}
          </Button>
          <LoadingButton loading={loading} onClick={sendResetPasswordRequest} disabled={!validEmail}>
            {t('sendResetPasswordRequest')}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
