'use client';
import LanguageIcon from '@mui/icons-material/Language';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { DK, GB } from 'country-flag-icons/react/1x1';
import { useParams, usePathname, useRouter } from 'next/navigation';
import { PropsWithChildren, useState } from 'react';

import { useTranslation } from '@/app/i18n/client';
import { languages } from '@/app/i18n/settings';
import { useMediaQuery } from '@/utils';

export const LoginPageWrapper: React.FC<PropsWithChildren> = ({ children }) => {
  const isBreakpoint600 = useMediaQuery(600);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const languageMenuOpen = Boolean(anchorEl);
  const openLanguageMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const closeLanguageMenu = () => {
    setAnchorEl(null);
  };
  const pathName = usePathname() ?? '';
  const router = useRouter();
  const params = useParams();
  const lng = params?.lng ?? 'en';

  const { t } = useTranslation(typeof lng === 'string' ? lng : 'en');

  const handleLanguageChange = (lang: string) => {
    const path = pathName.split(`/${lng}/`)[1];
    if (path) router.push(`/${lang}/${path}`);
    else router.push(`/${lang}`);
  };

  return (
    <Box sx={{ height: '100%' }}>
      <Box
        sx={{
          position: 'absolute',
          top: 15,
          left: 15,
          display: 'flex',
          gap: isBreakpoint600 ? 2 : 1,
        }}
      >
        <IconButton
          id="language-selection-button"
          aria-controls={languageMenuOpen ? 'language-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={languageMenuOpen ? 'true' : undefined}
          onClick={openLanguageMenu}
        >
          <LanguageIcon />
        </IconButton>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={languageMenuOpen}
          onClose={closeLanguageMenu}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          {languages.map((lang) => {
            const iconMap = { da: DK, en: GB };
            const IconName = iconMap[lang];
            return (
              <MenuItem
                key={lang}
                sx={{
                  '&:hover': {
                    cursor: 'pointer',
                  },
                }}
                onClick={() => {
                  closeLanguageMenu();
                  handleLanguageChange(lang);
                }}
              >
                <Box sx={{ display: 'flex', mr: 1 }}>
                  <IconName
                    style={{
                      borderRadius: '100%',
                      height: !isBreakpoint600 ? 24 : 36,
                      width: !isBreakpoint600 ? 24 : 36,
                    }}
                  />
                </Box>
                <Typography sx={{ verticalAlign: 'middle' }}>{t(`languages.${lang}`)}</Typography>
              </MenuItem>
            );
          })}
        </Menu>
      </Box>
      {children}
    </Box>
  );
};
