import { SvgIconTypeMap, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import Typography from '@mui/material/Typography';
import Link from 'next/link';

export type CrumbProps = {
  path: string;
  name: string;
  Icon?: OverridableComponent<SvgIconTypeMap<object, 'svg'>> & { muiName: string };
  isLast: boolean;
};

export const Crumb: React.FC<CrumbProps> = ({ path, name, Icon, isLast }) => {
  const theme = useTheme();
  if (isLast)
    return (
      <Box height={16} key={path} display={'flex'} justifyContent={'center'} alignItems={'center'} gap={1}>
        {Icon && <Icon sx={{ height: 16, width: 16 }} />}
        <Typography key={path}>{name}</Typography>
      </Box>
    );
  return (
    <Box sx={{ '&:hover': { textDecoration: 'underline' } }}>
      <Link
        key={path}
        style={{
          textDecoration: 'none',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 8,
          color: theme.palette.text.secondary,
        }}
        href={path}
      >
        {Icon && <Icon sx={{ height: 16, width: 16 }} />}
        {name}
      </Link>
    </Box>
  );
};
