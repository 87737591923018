import { SupabaseClient } from '@supabase/supabase-js';
import { DateTime } from 'luxon';
import { dissoc } from 'ramda';

import { Languages } from '@/app/i18n/settings';
import { Database } from '@/types/database.types';

// TODO: move these
export type OrganizationRPCResult = {
  organization_id: string | null;
  title: string | null;
  parent_organization_title: string;
  score: number;
  confidence: number;
  address: string;
  postal_code: string;
  task_count: number;
  task_status: string;
  energy_labels: string;
};

export type GetOrganizationsArgs = {
  supabase: SupabaseClient<Database>;
  customer_id: string;
  start: number;
  pageSize: number;
  orderBy?: keyof OrganizationRPCResult;
  ascending?: boolean;
  searchText?: string;
  selected_parent_organization_id_list?: string[];
};

export const getSingleProperty = async (supabase: SupabaseClient<Database>, id: string, lng: Languages) => {
  const property = await supabase
    .from('property')
    .select(
      `*, organization(*), part_lvl_2_prediction_condition(*, part_lvl_2: building_element_level_2(*, name: name->${lng}, description: description->${lng}, checklist: checklist->${lng})), buildings: building(*, addresses: address(*), task(*))`
    )
    .eq('id', id)
    .eq('part_lvl_2_prediction_condition.is_stale', false)
    .gte('part_lvl_2_prediction_condition.created_at', DateTime.now().minus({ days: 7 }).toISO())
    .single();
  return property;
};

export const getOrganizationById = async (supabase: SupabaseClient<Database>, id: string) => {
  const organization = await supabase.from('organization').select('*, property(id), task(*, )').eq('id', id).single();
  return organization;
};

export const getPropertiesByIds = async (supabase: SupabaseClient<Database>, id: string, lng: Languages) => {
  const property = await supabase
    .from('property')
    .select(
      `*, customer_id, organization(*), part_lvl_2_prediction_condition(*, part_lvl_2: building_element_level_2(*, name: name->${lng}, description: description->${lng}, checklist: checklist->${lng})), buildings: building(*, addresses: address(*), task(*))`
    )
    .or(`id.eq.${id},organization_id.eq.${id}`)
    .eq('part_lvl_2_prediction_condition.is_stale', false)
    .gte('part_lvl_2_prediction_condition.created_at', DateTime.now().minus({ days: 7 }).toISO());
  return property;
};

export const getTasksByOrganizationId = async (supabase: SupabaseClient<Database>, id: string, lng: Languages) => {
  const { data } = await supabase
    .from('task')
    .select(
      `*, constructive_activity(*), preventive_activity(*), remedial_activity(*), improvement_activity(*), buildings: building(*), part_lvl_2_task(*, part_lvl_2: building_element_level_2(*, name: name->${lng}, description: description->${lng}, checklist: checklist->${lng}, part_lvl_1: building_element_level_1(*, name: name->${lng})), part_lvl_2_condition(*, buildings: building(*), images: condition_img(*), score(*, short_text: short_text->${lng}, long_text: long_text->${lng})))`
    )
    .eq('organization_id', id);
  return data;
};

export const getPropertyBfeNumber = async (supabase: SupabaseClient<Database>, id: string) => {
  return await supabase.from('property').select('bfe_number').eq('id', id).single();
};

export const getOrganizationName = async (supabase: SupabaseClient<Database>, id: string) => {
  return await supabase.from('organization').select('name: title').eq('id', id).single();
};

export const getOrganizationsListRPC = async ({
  supabase,
  start,
  pageSize,
  searchText = '',
  ascending,
  orderBy,
  customer_id,
  selected_parent_organization_id_list,
}: GetOrganizationsArgs) => {
  // TODO test sql injextion

  const { data, error } = await supabase.rpc('get_organization_list', {
    v_start: start,
    v_page_size: pageSize,
    v_search_text: `%${searchText}%`,
    v_ascending: ascending || false,
    v_order_by: orderBy ?? 'title',
    v_customer_id: customer_id,
    v_selected_parent_organization_id_list: selected_parent_organization_id_list,
  });

  if (error) {
    return {
      data: null,
      error: error,
      count: 0,
    };
  }

  if (!data) {
    return {
      data: null,
      error: new Error('no data'),
      count: 0,
    };
  }

  const count = data[0]?.count;

  return {
    data: data.map((org) => dissoc('count', org)),
    error: null,
    count,
  };
};
