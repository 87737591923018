import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Link, Typography } from '@mui/material';
import { t } from 'i18next';

import { supportEmailAddress } from '@/utils/config';

type Props = {
  open: boolean;
  onClose: () => void;
};

export const HelpDialog: React.FC<Props> = ({ open, onClose }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{t('needHelp')}</DialogTitle>
      <DialogContent>
        <Typography>{t('needHelpDescription')}</Typography>
        <Box m={2} sx={{ textAlign: 'center' }}>
          <Link href={'mailto:' + supportEmailAddress}>{supportEmailAddress}</Link>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t('cancel')}</Button>
      </DialogActions>
    </Dialog>
  );
};
