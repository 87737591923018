'use client'; // Error components must be Client Components

import { captureException } from '@sentry/nextjs';
import { useEffect } from 'react';

import ErrorComponent from '@/components/ErrorComponent';

export default function Error({ error, reset }: { error: Error & { digest?: string }; reset: () => void }) {
  useEffect(() => {
    // Log the error to an error reporting service
    captureException(error);
    console.error(error);
  }, [error]);

  return <ErrorComponent error={error} reset={reset} />;
}
